<template>
  <div id="List">
    <br />
    <v-divider></v-divider>
    <br />
    <v-row>
      <v-col v-for="option in restaurantList" :key="option.Id" sm="12" md="8" lg="4" class="mx-auto">
        <res-com @refetch-data="fetchRestaurants" :item="option" :name="option.Name" :id="option.Id"></res-com>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Generate, NotifyAsync } from '@core/api/Chat';
import { GetRestaurants } from '@core/api/Restaurant';
import { mdiPlus } from '@mdi/js';
//import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { onMounted, ref } from '@vue/composition-api';
import ResCom from './ResCom.vue';
export default {
  components: {
    ResCom,
  },
  setup() {
    onMounted(() => {
      localStorage.removeItem('customerData')
    })
    const isAddResActive = ref(false)
    const restaurantList = ref([])
    const fetchRestaurants = () => {
      restaurantList.value = []
      GetRestaurants().then(response => {
        restaurantList.value = response
      })
    }
     const GenerateVapid = () => {
       Generate().then( res => {
         console.log('generated keys', res)
       })
     }

    const sendN = () => {
      NotifyAsync({BranchId: 1})
    }
    return {
      sendN,
      GenerateVapid,
      restaurantList,
      fetchRestaurants,
      isAddResActive,
      icons: {
        mdiPlus,
      },
    }
  },
  created() {
    this.fetchRestaurants()
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
