<template>
  <div class="ResCom">
    <v-menu width="300" v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
      <v-card>
        <vc-upload-image @refetch-data="$emit('refetch-data')" :object-id="id" table-name="Restaurant" />
      </v-card>
    </v-menu>
    <v-card>
      <router-link :to="{ name: 'AgentBranch', params: { id: id, name: name } }">
        <v-card class="mx-auto" :height="250" tile @contextmenu="show">
          <vc-image :height="250" :object-id="id" table-name="Restaurant" :name-text="name" />
        </v-card>
        <v-card-title>
          {{ name }} <v-spacer></v-spacer>

          <v-chip
            small
            :color="
              $store.state.status['restaurants'].find(obj => {
                return obj.value == item.Status
              }).variant || 'primary'
            "
            :class="`${
              $store.state.status['restaurants'].find(obj => {
                return obj.value == item.Status
              }).variant || 'primary'
            }--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.Status }}
          </v-chip>
        </v-card-title>
      </router-link>
    </v-card>
  </div>
</template>

<script>
import VcImage from '@/components/vc-avatar/VcImage.vue'
import VcUploadImage from '@/components/vc-input/Vc-UploadImage.vue'

export default {
  name: 'ResCom',
  components: { VcImage, VcUploadImage },
  props: {
    item: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    return { showMenu: false, x: 0, y: 0 }
  },

  methods: {
    show(e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
  },
}
</script>

<style scope>
.portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}
</style>
